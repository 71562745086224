<template>
  <div class="home">
    <div class="banner">
      <el-carousel trigger="click" :interval="5000">
        <el-carousel-item>
          <img :src="require('@/assets/img/banner-2.png')" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="container">
        <div class="company">
          <div class="title">
            <h4>公司介绍</h4>
          </div>
          <p class="text">
            <span style="color: #4e9ea9; font-weight: bold">shunfu科技</span>
            帮助各种规模的，移动游戏开发者进行游戏的制作、发行和推广，促进业务的发展和增长。
          </p>
        </div>
        <div class="product">
          <div class="game_title">
            <h4>产品介绍</h4>
          </div>
          <ul>
            <li v-for="item of game" :key="item.id">
              <div class="game">
                <div class="game_icon">
                  <img :src="item.icon" />
                </div>
                <div class="game_text">
                  <h4 class="game_title">{{ item.title }}</h4>
                  <p class="game_introduce">{{ item.introduce }}</p>
                </div>
              </div>
              <div class="game_img">
                <img
                  v-for="(item, index) of item.gameImg"
                  :key="index"
                  :src="item"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      game: [
        {
          id: 2,
          icon: require('@/assets/img/方块大作战/icon.jpg'),
          title: '《 方块大作战游戏软件 》',
          introduce:
            '危险的敌人正在攻击整个城市，城市已经陷入危机！ 被梦境试炼唤起的你，已经成为身负重任的战士！ 作为一名极具潜力的人类战士，你将与其他幸存者一起拿起武器，战胜危险',
          gameImg: [
            require('@/assets/img/方块大作战/1.png'),
            require('@/assets/img/方块大作战/2.png'),
            require('@/assets/img/方块大作战/3.png'),
            require('@/assets/img/方块大作战/4.png'),
            require('@/assets/img/方块大作战/5.png'),
            require('@/assets/img/方块大作战/6.png')
          ]
        }
        // {
        //   id: 2,
        //   icon: require('@/assets/img/sky_icon.png'),
        //   title: '《 造个天空城 》',
        //   introduce:
        //     '《 造个天空城 》是一款集合卡牌与建造玩法的休闲冒险手游，和我们一起创造你的天空之城吧！',
        //   gameImg: [
        //     require('@/assets/img/sky_1.png'),
        //     require('@/assets/img/sky_2.png'),
        //     require('@/assets/img/sky_3.png')
        //   ]
        // },
        // {
        //   id: 3,
        //   icon: require('@/assets/img/man_icon.png'),
        //   title: '《 少侠别打我 》',
        //   introduce:
        //     '《 少侠别打我 》是一款轻松幽默的放置游戏在游戏中，玩家培养自己的武林门派，收集名门侠客，与江湖魔道展开对决。快去修炼你的绝世神功吧！',
        //   gameImg: [
        //     require('@/assets/img/man_1.png'),
        //     require('@/assets/img/man_2.png'),
        //     require('@/assets/img/man_3.png')
        //   ]
        // },
        // {
        //   id: 4,
        //   icon: require('@/assets/img/wash_icon.png'),
        //   title: '《 疯狂洗剪吹 》',
        //   introduce:
        //     '《 疯狂洗剪吹 》是一款搞笑模拟经营游戏。你是天才发型师，还是无敌搞笑演员？快来解锁你的最强发行吧，哈哈哈哈哈。',
        //   gameImg: [
        //     require('@/assets/img/wash_1.png'),
        //     require('@/assets/img/wash_2.png'),
        //     require('@/assets/img/wash_3.png')
        //   ]
        // }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.banner {
  .el-carousel__item {
    ::v-deep img {
      width: 100%;
      height: 647px;
      // background-size: 100% 100%;
      background-position: center 100%;
      object-fit: cover;
    }
  }
  ::v-deep .el-carousel__container {
    height: 647px;
  }
}
.company {
  padding-bottom: 100px;
  border-bottom: 4px solid @blColor;
  .title {
    padding-top: 50px;
    text-align: center;
    h4 {
      font-size: 42px;
      opacity: 1;
    }
  }
  .text {
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
    opacity: 1;
    font-weight: normal;
  }
}
.product {
  .game_title {
    padding-top: 50px;
    text-align: center;
    h4 {
      font-size: 42px;
      opacity: 1;
    }
  }
  li {
    padding: 50px 0 100px 0;
    border-bottom: 4px solid @blColor;
    &:last-child {
      border-bottom: 0px solid @blColor;
    }
    .game {
      display: flex;
      .game_icon {
        margin: 40px;
        // width: 300px;
        // height: 300px;
        width: 280px;
        height: 280px;
        overflow: hidden;
        border-radius: 100%;
        img {
          width: 280px;
          height: 280px;
          // border-radius: 20%;
          // background-position: center 100%;
          // object-fit: cover;
          background-size: 100% 100%;
          transition: all 0.6s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      .game_text {
        width: 860px;
        padding-top: 40px;
        padding-left: 80px;
        .game_title {
          font-size: 28px;
          opacity: 1;
          font-weight: bold;
        }
        .game_introduce {
          margin-top: 20px;
          font-size: 24px;
          opacity: 1;
          font-weight: normal;
        }
      }
    }
    .game_img {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      img {
        width: 386px;
        height: 815px;
        margin-right: 13px;
        margin-bottom: 13px;
      }
    }
  }
}
</style>
